import { Component } from '@angular/core';

@Component({
  selector: 'ngx-login-layout',
  styleUrls: ['./login.layout.scss'],
  template: `
    <nb-layout windowMode>


      <nb-layout-column style="margin: auto; background: url(./assets/fondo_pantalla_logueo_150dpi.jpg) no-repeat fixed center; height:100vh; width: 100vw; display:flex; justify-content:center; align-items:center;">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>


    </nb-layout>
  `,
})
export class LoginLayoutComponent {}
