import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header style="background-color: #e51c81;" fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar state="compacted" class="menu-sidebar" tag="menu-sidebar" compact>
        <ng-content  select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column >
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer style="background-color: #dadada" fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  
}
