  <div  style="width: 100%; display: flex; align-items: center;">
    <div class="logo-container col" style="width: 100%; padding:0;"> 
      <img (click)="toggleSidebar()" src="./assets/clappi_isotipo.svg" alt="">
      <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" style="border-left: 1px solid white; margin-left: 20px; padding-left: 10px;">
        <nb-icon icon="menu-outline" style="color: white"></nb-icon>
      </a> -->
    </div> 

    <div class="col-5" style="width: 100%; display: flex; justify-content: center; align-items: center;">
      <a class="logo"  href="#" (click)="navigateHome()" style="color: white; font-size: 2.1rem; width: 100vw; text-align: center;"><img src="./assets/clappi_logo.svg" alt="Clappi Logo" srcset=""></a>
    </div>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select> -->
    <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
    <div class="col user-column">
      <nb-actions size="small">
        <nb-action class="control-item">
          <!-- <nb-search type="rotate-layout"></nb-search> -->
        </nb-action>
        <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action> -->
        <nb-action class="user-action mobile-user" *nbIsGranted="['view', 'user']" >
          <nb-user [nbContextMenu]="userMenu"
                   [onlyPicture]="true"
                   [name]="this.cuitData"
                   nbContextMenuTag="userMenu"
                   >
          </nb-user>
        </nb-action>
      </nb-actions>
      <nb-actions size="small">
        <nb-action class="control-item">
          <!-- <nb-search type="rotate-layout"></nb-search> -->
        </nb-action>
        <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action> -->
        <nb-action class="user-action large-user" *nbIsGranted="['view', 'user']" >
          <nb-user [nbContextMenu]="userMenu"
                   [onlyPicture]="false"
                   [name]="this.cuitData"
                   nbContextMenuTag="userMenu"
                   >
          </nb-user>
        </nb-action>
      </nb-actions>
    </div>
  </div>




