import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private globalData: any;

  setGlobalData(data: any) {
    this.globalData = data;
  }

  getGlobalData() {
    return this.globalData;
  }
}